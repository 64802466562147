import React from "react";
import HeaderSection2 from "../header/HeaderSection2";
import HeaderSection4 from "../header/HeaderSection4";
import HeaderSection4Contact from "../header/HeaderSection4Contact";
import FooterSection from "../footer/FooterSection";
import FooterSection3 from "../footer/FooterSection3";
import RightSideBar from "../sidebar/RightSideBar";

const Layout = ({ children }) => {
  return (
    <>
      <HeaderSection4Contact />
      {children}
      <RightSideBar />
      <FooterSection3 logo="assets/images/logo-1.png" />
    </>
  );
};

export default Layout;
