import React from 'react'

const LocationSection = () => {
  return (
    <div className="fz-contact-location-map">
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106135.73441187885!2d-84.50270248479252!3d33.76734000797871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045d6993098d%3A0x66fede2f990b630b!2zQXRsYW50YSwgR2VvcmdpYSwgSG9hIEvhu7M!5e0!3m2!1svi!2s!4v1733757503169!5m2!1svi!2s" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    </div>
  )
}

export default LocationSection