import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import BirthdayTabPane from './BirthdayTabPane';
import AnniversaryTabPane from './AnniverseryTabPane';
import CelebrationTabPane from './CelebrationTabPane';
import ProductViewModal from '../modal/ProductViewModal';
import { Link } from 'react-router-dom';

const IntroduceChefSection = () => {
    const [activeTab, setActiveTab] = useState('birthday');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    return (
        <section id="chef" className="hot-selling-section-2">
            <div className="container">
                <div className="fz-3-section-heading">
                    <h2 
                    style={{ textShadow: '2px 2px 5px rgba(0, 0, 0)' }}
                    className="fz-section-title">Meet Our Head Chef</h2>
                    <img
                        src="assets/images/IMG_9081.jpg"
                        className="fz-3-logo"
                    />
                    <p
                        style={{ textShadow: '2px 2px 5px rgba(0, 0, 0)' }}
                        className="fz-section-sub-title"
                    >
                        Head Chef Tran
                    </p>

                    {/* <div style = {{ marginTop: '2%'}}>
                        <p style = {{ fontSize: '20px'}}>With <span style = {{ fontWeight :'bold'}}>over 20 years of experience</span> in the culinary world,
                            whether you’re hosting a special event or a casual gathering, he brings expertise and artistry to every meal, <span style = {{ fontWeight :'bold'}}>ensuring an exceptional culinary journey for you and your guests.</span></p>

                    </div> */}

                    <div className='introduceChef'>
                        With <span style={{ fontWeight: 'bold' }}>over 20 years of experience</span> in the culinary world,
                        whether you’re hosting a special event or a casual gathering, he brings expertise and artistry to every meal, <span style={{ fontWeight: 'bold' }}>ensuring an exceptional culinary journey for you and your guests.</span>

                    </div>
                </div>


            </div>
            <ProductViewModal />
        </section>
    )
}

export default IntroduceChefSection