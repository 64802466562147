import React, { useContext } from "react";
import { FarzaaContext } from "../../context/FarzaaContext";

const MobileMenuSection = ({ navigate }) => {
  const { isDropdownOpen, handleDropdownToggle } = useContext(FarzaaContext);
  return (
    <div className="mean-bar">
      <a href="#nav" className="meanmenu-reveal">
        <span>
          <span>
            <span></span>
          </span>
        </span>
      </a>
      <nav className="mobile-mean-nav">
        <div className="mobile-menu-list-items">
        
         
        
        
          <div className="fz-nav-item mean-last">
            <a
              role="button"
              onClick={() => navigate("/")}
              className="fz-nav-link"
            >
              Home
            </a>
          </div>
          <div className="fz-nav-item mean-last">
            <a
              role="button"
              onClick={() => navigate("/")}
              className="fz-nav-link"
            >
              Service
            </a>
          </div>
          <div className="fz-nav-item mean-last">
            <a
              role="button"
              onClick={() => navigate("/")}
              className="fz-nav-link"
            >
              Portfolio
            </a>
          </div>
          <div className="fz-nav-item mean-last">
            <a
              role="button"
              onClick={() => navigate("/")}
              className="fz-nav-link"
            >
              Menu
            </a>
          </div>
          
          <div className="fz-nav-item mean-last">
            <a
              role="button"
              onClick={() => navigate("/booking")}
              className="fz-nav-link"
            >
              Booking
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MobileMenuSection;
