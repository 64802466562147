import React from 'react'
import { Link } from 'react-router-dom'

const OfferSection2 = () => {
    return (
        <section  id="menu"  className="fz-3-offer-section">
            <div className="container">

                <div  className="fz-3-section-heading">
                    <div className="section-heading__txt">
                        <h2 style={{ color: 'black' }} className="fz-section-title">Menu</h2>
                        <p style={{ color: 'black', fontSize: '20px' }} className="fz-section-sub-title">
                            Keep an eye out—our updated menu will be launching soon!
                        </p>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-md-12">
                        <div className="fz-3-single-offer">
                            {/* <p className="fz-3-single-offer__discount-percentage">10% off</p> */}
                            <h4 className="fz-3-single-offer__title">Stay tuned, the menu is getting a fresh update shortly.</h4>
                            <Link to="/" className="fz-3-single-offer__btn">Coming soon!</Link>
                        </div>
                    </div>

                    {/* <div className="col-md-6">
                        <div className="fz-3-single-offer fz-3-single-offer-2">
                            <p className="fz-3-single-offer__discount-percentage">10% off</p>
                            <h4 className="fz-3-single-offer__title">10% off on All 500gm & 1kg Cakes</h4>
                            <Link to="/shop" className="fz-3-single-offer__btn">Shop Now</Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default OfferSection2