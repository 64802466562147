import React from "react";
import { Link } from "react-router-dom";

const HeaderNavContact = ({ position, downArrow }) => {
  return (
    <nav className="mt-10 fz-header-nav">
      <ul className={`align-items-center ${position}`}>

        <li className="fz-nav-item">
          <Link to="/" className="fz-nav-link">
            Home
          </Link>
        </li>
        <li className="fz-nav-item">
          <Link to="/" className="fz-nav-link">
            Service
          </Link>
        </li>
        <li className="fz-nav-item">
          <Link to="/" className="fz-nav-link">
            Portfolio
          </Link>
        </li>
        <li className="fz-nav-item">
          <Link to="/" className="fz-nav-link">
            Menu
          </Link>
        </li>
        <li className="fz-nav-item">
          <Link to="/booking" className="fz-nav-link">
            Booking
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderNavContact;
