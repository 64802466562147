import React, { useState } from 'react';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';

const ContactFormSection = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [comment, setComment] = useState('');
  const [celebrating, setCelebrating] = useState('');
  const [size, setSize] = useState('');
  const [allergies, setAllergies] = useState('');
  const [about, setAbout] = useState('');
  const [describe, setDescribe] = useState('');
  const [anything, setAnything] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!firstName || !lastName || !email || !phoneNumber || !celebrating || !size || !date) {
      toast.error('Please fill out required fields.', { position: 'top-right' });
      return;
    }
    if (!isValidEmail(email)) {
      toast.warning('Please provide a valid email address.', { position: 'top-right' });
      return;
    }

    const formData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      celebrating,
      size,
      allergies,
      about,
      describe,
      anything,
      date,
    };

    // Send email using EmailJS
    emailjs
      .send('service_aatf5c8', 'template_6k1ycqf', formData, 'm6enWq-BZHKKda0Ll')
      .then(
        (response) => {
          toast.success('We have received your request and will respond within 24 hours.', { position: 'top-right' });
          setFirstName('');
          setLastName('');
          setEmail('');
          setPhoneNumber('');
          setDate('');
          setCelebrating('');
          setSize('');
          setAllergies('');
          setAbout('');
          setDescribe('');
          setAnything('');
        },
        (err) => {
          toast.error('Error sending form. Please try again.', { position: 'top-right' });
          console.error(err);
        }
      );
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="row g-xl-4 g-3">
        <div className="col-6 col-xxs-12">
          <label>First name (required)</label>
          <input
            type="text"
            name="first-name"
            id="first-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <label>Last name (required)</label>
          <input
            type="text"
            name="last-name"
            id="last-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <label>Email (required)</label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <label>Phone Number (required)</label>
          <input
            type="text"
            name="phone-number"
            id="phone-number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <label>What are you celebrating? (required)</label>
          <select
            name="celebrating"
            id="celebrating"
            value={celebrating}
            onChange={(e) => setCelebrating(e.target.value)}
          >
            <option value="">Select an option</option>
            <option value="birthday">Birthday</option>
            <option value="wedding">Wedding</option>
            <option value="anniversary">Anniversary</option>
            <option value="graduation">Graduation</option>
            <option value="promotion">Promotion</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="col-6 col-xxs-12">
          <label>What is the size of your party? (required)</label>
          <input
            type="text"
            name="size"
            id="size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
        </div>
        <div className="col-12 col-xxs-12">
          <label>What is the date of your event? (required)</label>
          <input
            type="text"
            name="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <label>Any severe allergies?</label>
          <input
            type="text"
            name="allergies"
            id="allergies"
            value={allergies}
            onChange={(e) => setAllergies(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <label>How did you find out about PrivateFusionChef?</label>
          <input
            type="text"
            name="about"
            id="about"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </div>
        <div className="col-12">
          <label>Please describe your event location</label>
          <textarea
            name="describe"
            id="describe"
            value={describe}
            onChange={(e) => setDescribe(e.target.value)}
          ></textarea>
        </div>
        <div className="col-12">
          <label>Anything else we need to know?</label>
          <textarea
            name="anything"
            id="anything"
            value={anything}
            onChange={(e) => setAnything(e.target.value)}
          ></textarea>
        </div>
      </div>

      <button type="submit" className="fz-1-banner-btn fz-comment-form__btn">
        Send Message
      </button>
    </form>
  );
};

export default ContactFormSection;
