import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import BirthdayTabPane from './BirthdayTabPane';
import AnniversaryTabPane from './AnniverseryTabPane';
import CelebrationTabPane from './CelebrationTabPane';
import ProductViewModal from '../modal/ProductViewModal';
import { Link } from 'react-router-dom';

const HotSellingCakeSection = () => {
    const [activeTab, setActiveTab] = useState('birthday');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
  return (
    <section id="contact" className="hot-selling-section mt-30">
        <div className="container">
            <div className="fz-3-section-heading">
                <h2 className="fz-section-title">Whether it’s a birthday celebration, an anniversary, or a dinner rehearsal, we take pride in crafting menus tailored to your tastes and creating extraordinary dining experience in the comfort of your own home.  </h2>
                <p className="fz-section-sub-title">Private Fusion Chef</p>
            </div>

            <Link to="/booking" className="fz-3-hot-sell__btn">Booking inquiries!</Link>
        </div>
        <ProductViewModal/>
    </section>
  )
}

export default HotSellingCakeSection