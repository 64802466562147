import React from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";
import { Link as Link1 } from 'react-router-dom';

const HeaderNav = ({ position, downArrow }) => {
  return (
    <nav className="fz-header-nav">
      <ul className={`align-items-center ${position}`}>
        <li style={{ cursor: 'pointer' }} className="fz-nav-item">
          <Link to="home" smooth={true} duration={500} className="fz-nav-link">
            Home
          </Link>
        </li>
        <li style={{ cursor: 'pointer' }} className="fz-nav-item">
          <Link to="service" smooth={true} duration={500} className="fz-nav-link">
            Service
          </Link>
        </li>
        <li style={{ cursor: 'pointer' }} className="fz-nav-item">
          <Link to="menu" smooth={true} duration={500} className="fz-nav-link">
            Menu
          </Link>
        </li>
        <li style={{ cursor: 'pointer' }} className="fz-nav-item">
          <Link to="portfolio" smooth={true} duration={500} className="fz-nav-link">
            Portfolio
          </Link>
        </li>


        <li style={{ cursor: 'pointer' }} className="fz-nav-item">
          <Link1 to="/booking" smooth={true} duration={500} className="fz-nav-link">
            Booking
          </Link1>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderNav;
