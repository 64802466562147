import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
const VerticalTestimonialSlider = () => {
  const sliderRef = useRef(null);
  const imgNavRef = useRef(null);

  useEffect(() => {
    const sliderSettings = {
      slidesToShow: 1,
      vertical: true,
      verticalSwiping: true,
      asNavFor: imgNavRef.current,
    };
    setSliderSetting(sliderSettings);
  }, []);
  const [sliderSettings, setSliderSetting] = useState({
    slidesToShow: 1,
    vertical: true,
    verticalSwiping: true,
    asNavFor: imgNavRef.current,
  });

  const testimonialImagesSettings = {
    slidesToShow: 1,
    draggable: false,
    arrows: false,
    fade: true,
    asNavFor: sliderRef.current,
  };

  return (
    <section id="service" className="fz-3-testimonial-section">
      <div className="container">
        <h1>Service</h1>
        <div className="row gy-5 justify-content-center align-items-center">

          <div className="col-lg-6 position-relative">

            <Slider
              {...sliderSettings}
              ref={sliderRef}
              className="fz-3-testimonial-slider-container"
              id="fz-3-testimonial-slider"
            >
              <div className="fz-3-single-testimony">
                <span className="quote-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p className="fz-3-single-testimony-txt">
                  Celebrate your special occasions with a bespoke dining experience designed just for you. Whether it’s a birthday, anniversary, or another milestone, we craft custom menus that perfectly reflect your taste.
                    </p>

                <div className="fz-3-rating">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </div>

                <div className="fz-3-reviewer">
                  <div className="fz-3-reviewer-img">
                    <img src="assets/images/your-fusion-chef-logo-notagline.png" alt="Person image" />
                  </div>

                  <div className="fz-3-reviewer-info">
                    <h6 className="fz-3-reviewer-name">Private Fusion Chef</h6>
                    <span className="fz-3-reviewer-label">
                      Head Chef
                    </span>
                  </div>
                </div>
              </div>

              <div className="fz-3-single-testimony">
                <span className="quote-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p className="fz-3-single-testimony-txt">
                  Treat yourself to personalized, chef-prepared meals by our private chef services centered around YOU.
                  Private Fusion Chef will create your bespoke menu filled with flavorful, nourishing, seasonal, and organic dishes.
                </p>

                <div className="fz-3-rating">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </div>

                <div className="fz-3-reviewer">
                  <div className="fz-3-reviewer-img">
                    <img src="assets/images/your-fusion-chef-logo-notagline.png" alt="Person image" />
                  </div>

                  <div className="fz-3-reviewer-info">
                    <h6 className="fz-3-reviewer-name">Private Fusion Chef</h6>
                    <span className="fz-3-reviewer-label">
                      Head Chef
                    </span>
                  </div>
                </div>
              </div>

              <div className="fz-3-single-testimony">
                <span className="quote-icon">
                  {/* <a style = {{ fontSize: '60px'}}>Service 1</a> */}
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p className="fz-3-single-testimony-txt">
                  Meal preparation services are the perfect solution when life gets too hectic.
                  Avoid the temptation of the drive-thru and come home to a home-cooked meal
                  with fully customizable meal plans to suit your family, diet, and lifestyle.
                </p>

                <div className="fz-3-rating">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </div>

                <div className="fz-3-reviewer">
                  <div className="fz-3-reviewer-img">
                    <img src="assets/images/your-fusion-chef-logo-notagline.png" alt="Person image" />
                  </div>

                  <div className="fz-3-reviewer-info">
                    <h6 className="fz-3-reviewer-name">Private Fusion Chef</h6>
                    <span className="fz-3-reviewer-label">
                      Head chef
                    </span>
                  </div>
                </div>
              </div>
            </Slider>

            <div className="fz-3-testimonial-slider-nav">
              <button
                type="button"
                onClick={() => sliderRef.current.slickPrev()}
              >
                <i className="fa-regular fa-angle-up"></i>
              </button>
              <button
                type="button"
                onClick={() => sliderRef.current.slickNext()}
              >
                <i className="fa-regular fa-angle-down"></i>
              </button>
            </div>
          </div>

          <div className="col-lg-6">
            <Slider
              {...testimonialImagesSettings}
              ref={imgNavRef}
              className="testimonial-img-slider"
            >
              {" "}
              <img src="assets/images/food199.jpg"  />
              <img src="assets/images/food222.jpg"  />
              <img src="assets/images/food1999.jpg" />
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerticalTestimonialSlider;
